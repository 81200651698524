import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
    const toggle = document.querySelectorAll("[data-toggle=\"playerStatsModal\"]");
    const modal = document.getElementById('playerStatsModal');

    if (modal) {
        const showModal = (event) => {
            createModalPlaceholder();

            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                modal.querySelector(".modal-content").innerHTML = this.response;

                const bsmodal = new bootstrap.Modal(modal, {
                    keyboard: false,
                    backdrop: 'static'
                });

                bsmodal.show();
            };

            xhr.open('GET', '/league/profile/' + event.currentTarget.dataset.id, true);
            xhr.send();
        };

        toggle.forEach((el) => { el.addEventListener("click", showModal); });

        modal.addEventListener('show.bs.modal', function (event) {
            document.getElementById('customBackdrop').classList.remove('show');

            setTimeout(() => {
                document.getElementById('customBackdrop').remove();
                document.getElementById('modalLoader').remove();
            }, 150);
        });

        modal.addEventListener('hidden.bs.modal', function (event) {
        });

        const createModalPlaceholder = () => {
            const backdrop = document.createElement('div');
            backdrop.className = "modal-backdrop fade";
            backdrop.id = "customBackdrop";

            const modalLoader = document.createElement('div');
            modalLoader.className = "modal-loader";
            modalLoader.id = "modalLoader";

            const loader = document.createElement('i');
            loader.className = "bi bi-arrow-clockwise";
            modalLoader.appendChild(loader);

            document.body.appendChild(backdrop);
            document.body.appendChild(modalLoader);

            backdrop.classList.add('show');
        };

        //Function for edit profile
        window.editMyProfile = (hash) => {
            createEditPlaceholder();

            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                modal.querySelector(".modal-body").innerHTML = this.response;
            };

            xhr.open('GET', '/league/profile/edit/' + hash, true);
            xhr.send();
        }

        const createEditPlaceholder = () => {
            const modalBody = modal.querySelector(".modal-body");

            const modalLoader = document.createElement('div');
            modalLoader.className = "modal-loader static";
            modalLoader.style.height = modalBody.offsetHeight + "px";
            modalLoader.id = "modalLoader";

            const loader = document.createElement('i');
            loader.className = "bi bi-arrow-clockwise";
            modalLoader.appendChild(loader);

            //Clean content
            modalBody.innerHTML = "";

            //Append loader
            modalBody.appendChild(modalLoader);
        };
    }
});
