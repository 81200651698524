'use strict';
import React from 'react';

class LeagueRankingFilters extends React.Component {
    render() {
        const labels = this.props.labels;
        const sportLabels = this.props.sportLabels;

        return (
            <React.StrictMode>
                <div className="flex justify-between align-items-center full-width margin-bottom-20">
                    <div class="league-name without-bar">{labels.ranking}</div>

                    <div className="order">
                        {labels.order_by}:
                        <select name="order" id="order" className="select-simple" onChange={this.props.orderRanking}>
                            {this.props.orders.map((el) => (
                                <>
                                    {(el !== 'positive_score' && el !== 'negative_score') && <option value={el}>{labels.orders[el]}</option>}
                                    {(el === 'positive_score' || el === 'negative_score') && <option value={el}>{sportLabels[el]}</option>}
                                </>
                            ))}
                        </select>
                    </div>
                </div>
            </React.StrictMode>
        );
    }
}

export default LeagueRankingFilters;
