'use strict';
import React, {useState} from 'react';


class MyLeaguesFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: props.labels,
            sports: props.sports
        };
        this.clickedSport = props.clickedSport;
        //this.setStatus = this.setStatus.bind(this);
    }


    render() {
        const labels = this.state.labels;
        const clickedSport = this.clickedSport;
        const active_sport = this.props.active_sport;



        return (
            <React.StrictMode>
                <div className="filters-row">
                    {this.state.sports.map((sport) => (
                        <button type="button" className={"btn btn-primary filter-button " + (sport === active_sport ? "active" : "")} onClick={()=>clickedSport(sport)}>
                           {labels.sport[sport]}
                        </button>
                    ))}
                </div>
            </React.StrictMode>
        );
    }
}

export default MyLeaguesFilters;
