import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
    const modal = document.getElementById('leagueVisitorModal');

    if (modal && !sessionStorage.getItem("guestLeague")) {
        //Generate modal object
        const myModal = new bootstrap.Modal(modal, {
            keyboard: false
        })

        //Open modal
        myModal.toggle();

        //Close modal
        document.getElementById('closeLeagueVisitorModal').addEventListener("click", (e) => {
            e.preventDefault();
            myModal.hide();

            sessionStorage.setItem("guestLeague", true);
        });
    }
});
